<template>
    <div class="peixunContainer">
        <div class="info">All the contestants are required to participate in the online training session at 16:00 on August 9th.</div>
        <div class="window">
            <img src="../../assets/peixun.png" class="windowImg"/>
            <div class="windowContent">
                <div class="logoLine">
                    <img src="../../assets/logo.png" class="logo" />
                </div>
                <div class="textLine">Pre-challenge Training</div>
                <div class="textLine">16:00 August 9th</div>
                <div class="btnLine">
                    <div class="btn" @click="toPeixun">Join the Training</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { checkPeixun } from '../../api/index'
import { getUserId } from '../../utils/store'
export default {
    data(){
        return{

        }
    },
    mounted(){

    },
    methods:{
        toPeixun(){
            let user_id = getUserId()
            if(user_id==='5d564b1ea4640b1c158b4567'){
                this.$confirm('是否确认进入培训?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(res=>{
                    let url = this.$router.resolve({
                        path:'/studentscreen',
                        query:{
                            roomId:'peixun'
                        }
                    })
                    window.open(url.href,'_blank')
                })
            }else{
                checkPeixun().then(res=>{
                    if(res.data.code===0){
                        this.$confirm('Are you sure you want to join the training?', 'Tips', {
                            confirmButtonText: 'confirm',
                            cancelButtonText: 'cancel',
                            type: 'warning'
                        }).then(res=>{
                            let url = this.$router.resolve({
                                path:'/studentscreen',
                                query:{
                                    roomId:'peixun'
                                }
                            })
                            window.open(url.href,'_blank')
                        })
                    }else{
                        this.$message.warning('Time not right')
                    }
                })
            }
        }
    }
}
</script>

<style lang="less">
.peixunContainer{
    width: 90%;
    .info{
        font-size: 2.6vh;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FEFEFE;
        text-align: center;
    }
    .window{
        margin-top: 1vh;
        position: relative;
        .windowImg{
            width: 100%;
            height: 60vh;
            left: 0;
            top:0;
            z-index: -1;
            position: absolute;
        }
        .windowContent{
            width: 100%;
            height: 100%;
            overflow: hidden;
            .logoLine{
                margin-top:8vh;
                text-align: center;
                .logo{
                    width: 20vh;
                }
            }
            .textLine{
                margin-top:2vh;
                font-size: 2.6vh;
                text-align: center;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #072D76;
            }
            .btnLine{
                margin-top:3vh;
                display: flex;
                justify-content: center;
                align-items: center;
                .btn{
                    width: 30%;
                    height: 5.4vh;
                    line-height: 5.4vh;
                    text-align: center;
                    background: #E74646;
                    border-radius: 10px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                    font-size: 2vh;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>